import React from 'react';
import PropTypes from 'prop-types';
import { useSite } from '@unisporkal/sites';
import { useLocale } from '@unisporkal/localization';
import FullFooter from './FullFooter/FullFooter';
import MinimalFooter from './MinimalFooter/MinimalFooter';
import useFooterPropsQuery from '../../../hooks/queries/useFooterPropsQuery';

const Footer = ({ layout }) => {
  const euLegalCountries = [
    'at',
    'be',
    'ch',
    'de',
    'dk',
    'es',
    'fi',
    'fr',
    'ie',
    'it',
    'nl',
    'pt',
    'se',
    'co.uk',
  ];

  const site = useSite();
  const { tld } = site;
  const locale = useLocale();
  const showEuLegalLink = euLegalCountries.includes(tld);
  const {
    data: { geoCountry },
  } = useFooterPropsQuery();
  const showUSPrivacyLink = locale === 'en-us' && geoCountry === 'USA';

  if (layout === 'pop_up' || layout === 'minimal' || layout === 'prism') {
    // todo - get host from header. will fix part of https://app.clickup.com/t/85zt9ye2u
    return (
      <MinimalFooter
        showEuLegalLink={showEuLegalLink}
        showUSPrivacyLink={showUSPrivacyLink}
      />
    );
  }
  return (
    <FullFooter
      showEuLegalLink={showEuLegalLink}
      showUSPrivacyLink={showUSPrivacyLink}
    />
  );
};

Footer.propTypes = {
  layout: PropTypes.string.isRequired,
};

export default Footer;
